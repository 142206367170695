/**
 * Created by Denis Kuzin on 18 october 2024
 */

const HoseLevel = function (location, progress) {
    this.location = location;
    this.progress = progress;

    this.level = new Level(location, progress);

    const hose = cleverapps.meta.getSelectedLocation();

    let currentLocation = hose.locationId;
    let currentProgress = hose.getProgress();
    if (hose.isCompleted() && !cleverapps.meta.isCompleted()) {
        currentLocation++;
        currentProgress = 0;
    }

    if (this.location < currentLocation) {
        this.type = Level.TYPE_PASSED;
    } else if (this.location > currentLocation) {
        this.type = Level.TYPE_NOTPASSED;
    } else if (this.progress < currentProgress) {
        this.type = Level.TYPE_PASSED;
    } else if (this.progress === currentProgress) {
        this.type = Level.TYPE_CURRENT;
    } else {
        this.type = Level.TYPE_NOTPASSED;
    }

    this.stars = (this.type === Level.TYPE_PASSED) ? 3 : 0;
};

HoseLevel.prototype.onPressed = function () {
    return this.level.onPressed();
};

HoseLevel.prototype.isHard = function () {
    return this.level.isHard();
};

HoseLevel.prototype.isBonus = function () {
    return this.level.isBonus();
};

HoseLevel.prototype.isTricky = function () {
    return this.level.isTricky();
};

HoseLevel.prototype.getHumanReadableNumber = function () {
    return this.location * HoseLocation.LEVELS_PER_LOCATION + this.progress + 1;
};