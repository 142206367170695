/**
 * Created by Ivan on 31.01.2025
 */

const BonusLevelComponent = cc.Node.extend({
    ctor: function () {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        const chestPath = bundles.game.jsons.bonus_chest_horizontal_json;
        const chest = this.chest = new cleverapps.Spine(chestPath);

        this.addChild(chest);
        chest.setAnimation(0, "animation", true);
        chest.setVisible(false);

        cleverapps.UI.wrap(this);
    },

    showUp: function () {
        let actions = [
            new cc.DelayTime(0.2),
            StandartAnimations.showUp(this.chest, { flash: true })
        ];

        if (Game.currentGame.collectedCoins) {
            actions = actions.concat([
                new cc.CallFunc(() => {
                    this.chest.setAnimation(0, "open", false);
                }),
                new cc.DelayTime(1),
                StandartAnimations.coinCloud(this, {
                    reward: Game.currentGame.collectedCoins
                })
            ]);
        }

        this.chest.runAction(new cc.Sequence(actions));
    }
});